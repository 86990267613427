import axios from 'axios'
import { debounce, pick, isEmpty, reduce, filter } from 'lodash'
import { isValidNumber } from './validation'

import { InfluxDB } from "@influxdata/influxdb-client";

export const SET_WELLS = 'SET_WELLS'
export const SET_DATA_FOR_WELL = 'SET_DATA_FOR_WELL'

export const fetchWellData = () => {
  return async (dispatch) => {

    return new Promise((resolve, reject) => {
      Promise.all([
        dispatch(fetchWellDataByName('D4L18225_3', 'groundwater level', "TEST_SENSOR")),
        dispatch(fetchWellDataByName('D4L18225_1', 'temperatur', "TEST_SENSOR")),
        dispatch(fetchWellDataByName('D4L18225_2', 'conductivity', "TEST_SENSOR")),

        dispatch(fetchWellDataByName('T4L04124_3', 'groundwater level', "T4L04124")),
        dispatch(fetchWellDataByName('T4L04124_1', 'temperatur', "T4L04124")),
        dispatch(fetchWellDataByName('T4L04124_2', 'conductivity', "T4L04124")),

        dispatch(fetchWellDataByName('T4L04128_3', 'groundwater level', "T4L04128")),
        dispatch(fetchWellDataByName('T4L04128_1', 'temperatur', "T4L04128")),
        dispatch(fetchWellDataByName('T4L04128_2', 'conductivity', "T4L04128")),

        dispatch(fetchWellDataByName('T4L04129_3', 'groundwater level', "T4L04129")),
        dispatch(fetchWellDataByName('T4L04129_1', 'temperatur', "T4L04129")),
        dispatch(fetchWellDataByName('T4L04129_2', 'conductivity', "T4L04129")),

        dispatch(fetchWellDataByName('T4L04130_3', 'groundwater level', "T4L04130")),
        dispatch(fetchWellDataByName('T4L04130_1', 'temperatur', "T4L04130")),
        dispatch(fetchWellDataByName('T4L04130_2', 'conductivity', "T4L04130")),

        dispatch(fetchWellDataByName('T4L04131_3', 'groundwater level', "T4L04131")),
        dispatch(fetchWellDataByName('T4L04131_1', 'temperatur', "T4L04131")),
        dispatch(fetchWellDataByName('T4L04131_2', 'conductivity', "T4L04131")),

        dispatch(fetchWellDataByName('T4L04132_3', 'groundwater level', "T4L04132")),
        dispatch(fetchWellDataByName('T4L04132_1', 'temperatur', "T4L04132")),
        dispatch(fetchWellDataByName('T4L04132_2', 'conductivity', "T4L04132"))
      ]).then(() => {
        resolve()
      })

    })
  }
}

export const fetchWellDataByName = (sensor_name, sensor_application, sensor_id) => {
  const { InfluxDB } = require('@influxdata/influxdb-client')

  const token = '8GGu_KQE0Pf59DIKUCAHrFcOfENBlJ5--XeiTcIdYrXwlnAD4EvRZaacwH-wa9sojIg3j0oyZAkmyecbVea4Cw=='
  const org = 'blubeyond'
  const bucket = 'waterwells'
  const client = new InfluxDB({ url: 'https://mfm.azure.blu-beyond.com', token: token })

  let result = []

  console.log('fetching wells')


  return async (dispatch) => {
    return new Promise((resolve, reject) => {
      const queryApi = client.getQueryApi(org)

      const query = 'from(bucket: "' + bucket + '") |> range(start: 0) |> filter(fn:(r) => r._measurement == "' + sensor_name + '" and r._field == "value")'
      queryApi.queryRows(query, {
        next(row, tableMeta) {
          const o = tableMeta.toObject(row)
          result.push(o)
        },
        error(error) {
          console.error(error)
        },
        complete() {

          // result = result.map(x => {"value": 123, "time":"12 Uhr"});
          let formatedResults = result.map(o => ({
            time: o._time, value: o._value
          }))

          dispatch({
            type: SET_DATA_FOR_WELL,
            data: formatedResults,
            sensor_application: sensor_application,
            sensor_id: sensor_id
          })

          console.log('\\nFinished SUCCESS')
          resolve()
        },
      })
    })
  }
}
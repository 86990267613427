import { createStore, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import reducer from './reducers'

const rootReducer = reducer
let composeEnhancers = compose

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-underscore-dangle, no-undef
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
}

const configureStore = () => {
  return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))
}

export default configureStore

import React, { useRef, useEffect, useState } from 'react';
import Mapboxmap from '../components/Mapboxmap'
import Dashboard from '../components/Dashboard'
import { useTranslation } from 'react-i18next';
import ReadMore from '../components/ReadMore'
// import Giraffe from '../components/Giraffe'
// import { Link, useHistory } from 'react-router-dom'
// import Select from 'react-select'
// import { useSelector, useDispatch } from 'react-redux'
// import { useTranslation } from "react-i18next"
// import { isEmpty } from 'lodash'

// const ReadMore = ({ children }) => {
//   const text = children;
//   const [isReadMore, setIsReadMore] = useState(true);
//   const toggleReadMore = () => {
//     setIsReadMore(!isReadMore);
//   };
//   return (
//     <p className="text">
//       {isReadMore ? text.slice(0, 150) : text}
//       <span onClick={toggleReadMore} className="read-or-hide">
//         {isReadMore ? "...read more" : " show less"}
//       </span>
//     </p>
//   );
// };

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import Footer from '../components/Footer';


const Home = (props) => {
  // const history = useHistory()
  // const { t } = useTranslation()
  // const dispatch = useDispatch()
  const { t, i18n } = useTranslation();
  const lngs = {
    en: { nativeName: t('mainMenu.language1') },
    de: { nativeName: t('mainMenu.language2') }
  };
  const position = [8.45201469165197, 37.669778051562254]
  const bttButton = document.getElementById("btt-button")
  const myRef = useRef(null)
  const [scrollY, setScrollY] = useState('');
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const executeScroll = () => myRef.current.scrollIntoView()
  //console.log(saveDisabled)
  function logit() {
    if (window.pageYOffset > 800) {
      setScrollY('show');
    }
  }

  function watchScroll() {
    window.addEventListener("scroll", logit);

  }

  useEffect(() => {

    watchScroll();
    return () => {
      window.removeEventListener("scroll", logit);
    };
  }, [scrollY]);

  return (
    <div>
      <div className="relative bg-white overflow-hidden">
        <a className={scrollY} id="btt-button" onClick={executeScroll}>
          <div className="p-3">
            <svg className="fill-current text-white contain" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
              viewBox="0 0 330 330" >
              <path id="XMLID_224_" d="M325.606,229.393l-150.004-150C172.79,76.58,168.974,75,164.996,75c-3.979,0-7.794,1.581-10.607,4.394
	l-149.996,150c-5.858,5.858-5.858,15.355,0,21.213c5.857,5.857,15.355,5.858,21.213,0l139.39-139.393l139.397,139.393
	C307.322,253.536,311.161,255,315,255c3.839,0,7.678-1.464,10.607-4.394C331.464,244.748,331.464,235.251,325.606,229.393z"/>
            </svg> </div></a>
        <div className="max-w-7xl mx-auto">
          
          
          <div className="relative pb-8 bg-white sm:pb-16 lg:pb-20 lg:w-full lg:pb-28 xl:pb-32">
            <div className="fixed bg-white w-full top-0 left-0 shadow-lg z-50">
              <div className="max-w-7xl mx-auto z-50 px-4 sm:px-6 lg:px-8 relative bg-white w-full py-6">
                
                <nav className="w-full flex justify-between lg:justify-space" aria-label="Global">
                  {/* Logo */}
                  <div className="flex items-center justify-between w-full lg:w-auto relative">
                    <a className="logo-mfm" href="/" style={{ top: "-90px" }}>
                      <img className="descktop" src="https://www.menschenfuermenschen.de/wp-content/themes/menschen/images/logo2.svg" alt="Logo"></img>
                    </a>
                    <div className="block z-50  flex items-center lg:hidden h-5">
                      <div className="right-0 top-0 absolute">
                        <button onClick={() => setMenuIsOpen(!menuIsOpen)} type="button" className="bg-white rounded-lg inline-flex items-center justify-center text-gray-400 hover:text-gray-900 " aria-expanded="false">
                          <span className="sr-only">Open main menu</span>
                          <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* Desktop Menue */}
                  <div className="relative hidden lg:block z-50 lg:flex lg:flex-row" /* style={{display: flex}} */>
                    <div className='pr-6'>
                      {Object.keys(lngs).map((lng) => (
                        <button className='font-medium text-gray-500 hover:text-gray-900 text-base' key={lng} type="submit" onClick={() => i18n.changeLanguage(lng)}>
                          {lngs[lng].nativeName}
                        </button>
                      ))}
                    </div>
                    <div>
                      <a href="https://www.menschenfuermenschen.de" target="_blank" className="font-medium text-gray-500 hover:text-gray-900 pr-6 text-base">{t('mainMenu.about')}</a>
                      <a href="https://www.menschenfuermenschen.de/ihre-unterstuetzung/spenden/online-spenden/" target="_blank" className="font-medium text-white bg-mfm-red py-6 px-6">{t('mainMenu.accentButton')}</a>
                      </div>
                  </div>

              </nav>
            </div>
          </div>

          {/* Mobile Menu */}
            {menuIsOpen && (
              <div className="fixed z-40 inset-x-0 transition transform origin-top-right lg:hidden" style={{ top: "60px" }}>
                <div className="shadow-lg bg-white  overflow-hidden">
                  <div className="px-5 pt-4 flex items-center justify-between">
                    <div>

                    </div>
                    <div className="-mr-2">
                      <button onClick={() => setMenuIsOpen(!menuIsOpen)} type="button" className="bg-white rounded-lg p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Close main menu</span>
                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="px-2 pt-2 pb-3 space-y-1">
                   
                      <div className='block px-3 py-2 rounded-lg text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50'>
                        {Object.keys(lngs).map((lng) => (
                          <button key={lng} type="submit" onClick={() => i18n.changeLanguage(lng)}>
                            {lngs[lng].nativeName}
                          </button>
                        ))}
                      </div>
                      
                        <a href="https://www.menschenfuermenschen.de" target="_blank" className="block px-3 py-2 rounded-lg text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">{t('mainMenu.about')}</a>
                        <a href="https://www.menschenfuermenschen.de/ihre-unterstuetzung/spenden/online-spenden/" target="_blank" className="block px-3 py-2 rounded-lg text-base font-medium text-prime hover:text-gray-900 hover:bg-gray-50">{t('mainMenu.accentButton')}</a>
                      
                    
                  </div>
                </div>
              </div>
            )}
            {/* https://thumbs.dreamstime.com/b/wasserpumpe-4710501.jpg */}
            
            
            {/* Main */}
            
            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 lg:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="text-center xl:text-left  w-full">
               
               {/* H1 */}
                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl lg:text-5xl pt-12  leading-3" style={{ lineHeight: '60px' }}>
                  <span className="block text-prime xl:inline">{t('headlineH1.part1')}</span>
                  {/* {t('headlineH1.part1', { date: new Date().toLocaleString() })} */}
                  <span className="hidden xl:block xl:inline leading-4"> -	</span>
                  <span className="block  xl:inline leading-4">{t('headlineH1.part2')}</span>
                </h1>

                <div className="mt-4">
                <ReadMore>
                {t('firstTextSection.text')}
                </ReadMore>
                <p className="text-lg text-gray-700 pointer text-prime">
                <a href='https://www.menschenfuermenschen.de/erfolgsmodell/unsere-schwerpunkte/wasser/' target="_blank" className=""><b>{t('firstTextSection.buttonText')}</b>{t('firstTextSection.buttonDescription')}</a>
                </p>
                </div>

                {/* Numbers */}
                <p className="text-lg pt-4 text-gray-700 font-bold mt-4">{t('numbers.headline')}</p>

                <dl className="mt-10 text-center grid sm:grid-cols-2 sm:gap-8 flex justify-center">
                  <a className=" group " target="_blank" >
                  <div className="flex flex-col">
                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-prime ">{t('numbers.numberDescription1')}</dt>
                    <dd className="order-1 text-5xl font-extrabold text-gray-600">{t('numbers.number1')}</dd>
                  </div>
                  </a>
                  <div className="flex flex-col mt-10 sm:mt-0">
                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-prime">{t('numbers.numberDescription2')}</dt>
                    <dd className="order-1 text-5xl font-extrabold text-gray-600">{t('numbers.number2')}</dd>
                  </div>
                </dl>
              </div>
              <p className="text-lg pt-4 text-gray-700 font-bold mt-4">{t('secondTextSection.textHeadline')}</p>
                  <ReadMore>
                  {t('secondTextSection.text')}
                  </ReadMore>
              <div className="w-full relative mt-6 z-10 scroll-margin-top" style={{ height: '600px', width: "100%" }} ref={myRef}>

                <Mapboxmap className="z-10" style={{ height: '600px', width: "100%", zindex: '10' }}></Mapboxmap>
              </div>

{/*               <h3 className="text-2xl tracking-tight font-extrabold text-gray-900 sm:text-3xl lg:text-3xl pt-12">Die Anforderungen an Wasserversorgungssysteme steigen</h3>
 */}


                  <p className="text-lg pt-4 text-gray-700 font-bold mt-4">{t('thirdTextSection.textHeadline')}</p>
                  <ReadMore>
                  {t('thirdTextSection.text')}
                 </ReadMore>

              <div className="w-full relative pt-12" >

                {/* <MapContainer center={[8.765231115920214, 39.487973279803974]} zoom={10} scrollWheelZoom={false} className="w-full" style={{height: '600px', width: "100%"}} >
                    <TileLayer
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={position}>
                      <Popup>
                        A pretty CSS3 popup. <br /> Easily customizable.
                      </Popup>
                    </Marker>
                  </MapContainer> */}
              </div>
              <div className="w-full relative  scroll-margin-top " id='dashboard'>
                <Dashboard></Dashboard>
              </div>
            </main>
          </div>
        </div>
      </div>
      {/* <Giraffe></Giraffe> */}
                  <Footer></Footer>
    </div>
  )
}

export default Home

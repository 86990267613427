
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { useTranslation } from 'react-i18next';

export default function Footer() {

  const { t, i18n } = useTranslation();
  const lngs = {
    en: { nativeName: t('mainMenu.language1') },
    de: { nativeName: t('mainMenu.language2') }
  };

  const footerNavigation = {

    legal: [
  
      { name: t('footerLinks.contact'), href: 'https://www.menschenfuermenschen.de/ueber-uns/service/ansprechpartner/' },
      { name: t('footerLinks.press'), href: 'https://www.menschenfuermenschen.de/ueber-uns/service/presse/' },
      { name: t('footerLinks.imprint'), href: 'https://www.menschenfuermenschen.de/impressum/' },
      { name: t('footerLinks.dataProtection'), href: 'https://www.menschenfuermenschen.de/datenschutz/' },
    ],
    support: [
  
      { name: t('footerLinks.foundation'), href: 'https://www.menschenfuermenschen.de/ueber-uns/die-stiftung/' },
     /*  { name: t('footerLinks.about'), href: 'https://www.menschenfuermenschen.de/' },
      { name: t('footerLinks.partner'), href: 'https://www.blu-beyond.com/' }, */
      { name: t('footerLinks.transparancy'), href: 'https://www.menschenfuermenschen.de/ueber-uns/transparenz/' },
      { name: t('footerLinks.waterHygiene'), href: 'https://www.menschenfuermenschen.de/erfolgsmodell/unsere-schwerpunkte/wasser/' },
  
    ],
    social: [
  
      {
        name: 'Instagram',
        href: 'https://www.instagram.com/stiftung_menschenfuermenschen/',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: 'Twitter',
        href: 'https://twitter.com/mfm_1981',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
          </svg>
        ),
      },
      {
        name: 'Youtube',
        href: 'https://www.youtube.com/user/MenschenFMenschen',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path d="M 4.19 1 V 1.5 V 1 Z M 9.5 12.94 H 9 C 9 13.1227 9.09964 13.2908 9.2599 13.3786 C 9.42015 13.4663 9.61549 13.4597 9.7694 13.3612 L 9.5 12.94 Z M 9.5 4.28 L 9.7694 3.85879 C 9.61549 3.76035 9.42015 3.75369 9.2599 3.84142 C 9.09964 3.92915 9 4.0973 9 4.28 H 9.5 Z M 16.27 8.61 L 16.5394 9.03121 C 16.6831 8.93932 16.77 8.78054 16.77 8.61 C 16.77 8.43946 16.6831 8.28067 16.5394 8.18879 L 16.27 8.61 Z M 23.75 4.44 C 23.75 2.264 21.986 0.5 19.81 0.5 V 1.5 C 21.4337 1.5 22.75 2.81628 22.75 4.44 H 23.75 Z M 19.81 0.5 H 4.19 V 1.5 H 19.81 V 0.5 Z M 4.19 0.5 C 2.014 0.5 0.25 2.264 0.25 4.44 H 1.25 C 1.25 2.81628 2.56628 1.5 4.19 1.5 V 0.5 Z M 0.25 4.44 V 13.56 H 1.25 V 4.44 H 0.25 Z M 0.25 13.56 C 0.25 14.605 0.665106 15.6071 1.404 16.346 L 2.11111 15.6389 C 1.55975 15.0875 1.25 14.3397 1.25 13.56 H 0.25 Z M 1.404 16.346 C 2.14289 17.0849 3.14505 17.5 4.19 17.5 V 16.5 C 3.41026 16.5 2.66246 16.1903 2.11111 15.6389 L 1.404 16.346 Z M 4.19 17.5 H 19.81 V 16.5 H 4.19 V 17.5 Z M 19.81 17.5 C 21.986 17.5 23.75 15.736 23.75 13.56 H 22.75 C 22.75 15.1837 21.4337 16.5 19.81 16.5 V 17.5 Z M 23.75 13.56 V 4.44 H 22.75 V 13.56 H 23.75 Z M 10 12.94 V 4.28 H 9 V 12.94 H 10 Z M 9.2306 4.70121 L 16.0006 9.03121 L 16.5394 8.18879 L 9.7694 3.85879 L 9.2306 4.70121 Z M 16.0006 8.18879 L 9.2306 12.5188 L 9.7694 13.3612 L 16.5394 9.03121 L 16.0006 8.18879 Z" />
          </svg>
        ),
      },
  
    ],
  }

  return (
    <footer className="bg-gray-50" aria-labelledby="footerHeading">
        {/* <h2 id="footerHeading" className="sr-only">
          Footer
        </h2> */}

        <div className="max-w-7xl mx-auto pt-16 pb-8 px-4 sm:px-6 lg:pt-24 lg:px-8 flex flex-col ">
          
          <div className="xl:grid xl:grid-cols-3 flex space-x-8">
            
            <div className="">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Stiftung Menschen für Menschen
                </h3>
                <small className="mb-2">Karlheinz Böhms Äthiopienhilfe</small>
                <p className="mt-4 text-base text-gray-500 pb-5 mb-4">
                Brienner Straße 46<br></br>
                80333 München
                </p>
                <a href="https://www.menschenfuermenschen.de/ihre-unterstuetzung/spenden/online-spenden/" target="_blank" className="font-medium text-white bg-mfm-red py-6 px-6">{t('mainMenu.accentButton')}</a>
              </div>
          
            <div className="">
              <div className="md:grid  md:gap-8">
                <div className="md:mt-0">
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">{t('footerLinks.headline1')}</h3>
                  <ul className="mt-4 space-y-4">
                    {footerNavigation.support.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
              
            <div className="">
              <div className="md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">{t('footerLinks.headline2')}</h3>
                <ul className="mt-4 space-y-4">
                  {footerNavigation.legal.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            
          </div>

          <div className="mt-12 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between lg:mt-16">
            
            <div className="flex space-x-6 md:order-2 items-center">
              {footerNavigation.social.map((item) => (
                <a key={item.name} href={item.href} target="_blank" className="text-gray-400 hover:text-gray-500 flex items-center">
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>

            <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
              &copy; {(new Date().getFullYear())} Stiftung Menschen für Menschen
            </p>

          </div>

        </div>

      </footer>
  );
}

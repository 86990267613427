import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import { useDispatch, useSelector } from 'react-redux'
import { imageOverlay } from 'leaflet';

mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = 'pk.eyJ1IjoiMjU2NzkxMCIsImEiOiJja215eHFzeGwwOGZtMm9wazAwb3ZxdGJyIn0.cZNwdTVYSq7v-fjLKE2JKA';

var geojson = {
  'type': 'geojson',
  'data': {
    'type': 'FeatureCollection',
    'features': []
  }
}

var geojson2 = {
  'type': 'geojson',
  'data': {
    'type': 'FeatureCollection',
    'features': []
  }
}

var sources = ["places", "places_with_no_sensor"];




const Map = () => {
  const mapContainer = useRef();
  const dispatch = useDispatch()
  const [lng, setLng] = useState(39.1713);
  const [lat, setLat] = useState(9.1923);
  const [zoom, setZoom] = useState(5.86);
  const [switchName, setSwitchName] = useState("Satelit");
  const [satelit, setSatelit] = useState(false);
  const wells = useSelector(state => state.wells)


  // '<strong>'+ element.name + '</strong><p>Click to view details</p>',
  generateMarkers()

  function generateMarkers() {
    wells.forEach(well => {
      if (well.hasSensor) {
        geojson.data.features.push(
          {
            'type': 'Feature',
            'properties': {
              'description':
                '<div className="rounded-lg w-32 bg-blue-100"><div className=""><div className="aspect-w-3 aspect-h-2"><img className="object-cover shadow-lg rounded-t-lg h-20 w-full" src="/img/wells' + well.imageURL + '" alt="" /></div><div className="px-3 py-2"><h3 className=" mt-0 text-base text-prime">' + well.name + '</h3><p className="text-gray-400 text-xs">Zum ansehen klicken</p></div></div></div>',
              'id': well.id
            },
            'geometry': {
              'type': 'Point',
              'coordinates': well.coordinates
            }
          }
        )
      }
      else {
        geojson2.data.features.push(
          {
            'type': 'Feature',
            'properties': {
              'description':
                '<div className="rounded-lg w-32 bg-blue-100"><div className=""><div className="aspect-w-3 aspect-h-2"><img className="object-cover shadow-lg rounded-t-lg h-20 w-full" src="/img/wells' + well.imageURL + '" alt="" /></div><div className="px-3 py-2"><h3 className=" mt-0 text-base text-prime">' + well.name + '</h3><p className="text-gray-400 text-xs">Zum ansehen klicken</p></div></div></div>',
              'id': well.id
            },
            'geometry': {
              'type': 'Point',
              'coordinates': well.coordinates
            }
          }
        )
      }
    });
  }
  function changeSatelite() {
    setSatelit(true)
    console.log(satelit)
  }

  // var layerList = document.getElementById('menu');
  // var inputs = layerList.getElementsByTagName('button');

  // function switchLayer() {
  //   console.log(satelit)
  //   // setSatelit(!satelit)
  //   if (satelit == false) {

  //     map.setStyle('mapbox://styles/2567910/ckmyzygmm0l8y17lbrf72kl1l');
  //   } else {

  //     map.setStyle('mapbox://styles/2567910/cknsok2wo0iwn17obl4k7btqw');
  //   }
  //   changeSatelite()
  //   console.log(satelit)
  // }
  // for (var i = 0; i < inputs.length; i++) {
  //   inputs[i].onclick = switchLayer;
  // }


  //Sateliten: mapbox://styles/2567910/ckmyzygmm0l8y17lbrf72kl1l
  //Normal: mapbox://styles/2567910/cknsok2wo0iwn17obl4k7btqw
  useEffect(() => {

    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/2567910/cknsok2wo0iwn17obl4k7btqw',
      center: [38.901726683055074, 9.844542922656158],
      zoom: 6.5
    });


    map.on('load', function () {
      map.addSource('places', geojson);
      map.addSource('places_with_no_sensor', geojson2);
      // Add a layer showing the places.
      //   map.once('load', function loaded() {
      //     map.batch(function (batch) {
      //         wells.forEach(function eachFilter(well, index) {
      //             var id = 'well-' + index;
      //             map.addSource(id, {type: 'geojson', data: generateMarker(well)});
      //             map.addLayer({
      //                 id: id + '-fill',
      //                 type: 'fill',
      //                 source: id,
      //                 paint: {'fill-color': 'red', 'fill-opacity': 0.6}
      //             });
      //         });
      //     });
      // });
      map.addLayer({
        'id': 'places',
        'type': 'circle',
        'source': 'places',
        'paint': {
          'circle-color': '#0755a8',
          'circle-radius': 6,
          'circle-stroke-width': 4,
          'circle-stroke-color': '#a8c3e0'
        }
      });
      map.addLayer({
        'id': 'places_with_no_sensor',
        'type': 'circle',
        'source': 'places_with_no_sensor',
        'paint': {
          'circle-color': '#3e4c5e',
          'circle-radius': 6,
          'circle-stroke-width': 4,
          'circle-stroke-color': '#a9acb0'
        }
      });

      // Create a popup, but don't add it to the map yet.
      var popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false
      });
      sources.forEach(item => {
        map.on('click', item, function (e) {
          console.log(e.features[0].properties.id)
          document.querySelector("#dashboard-" + e.features[0].properties.id).scrollIntoView({ behavior: 'smooth' });
        });

        map.on('mouseenter', item, function (e) {
          map.getCanvas().style.cursor = 'pointer';

          var coordinates = e.features[0].geometry.coordinates.slice();
          var description = e.features[0].properties.description;

          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          popup.setLngLat(coordinates).setHTML(description).addTo(map);
        });

        map.on('mouseleave', item, function () {
          map.getCanvas().style.cursor = '';
          popup.remove();
        });
      })
    });
      return () => map.remove();
    }, []);

    return (
      <div>
        <div className="map-container" ref={mapContainer} />
        {/* <div className="absolute top-0 left-0" id="menu">
        <button  id="switchMapStyle" type="button" class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium  text-gray-700 bg-white hover:bg-gray-50 ">
          {switchName}
        </button>

      </div> */}
      </div>
    );
  };

  export default Map
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom'
import App from './App';
import { Provider } from 'react-redux'
import configureStore from './store'

import './i18n';

import reportWebVitals from './reportWebVitals';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')
const store = configureStore()

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename={baseUrl}>
      <React.StrictMode>
        <Suspense fallback="...is loading">
          <App />
        </Suspense>
      </React.StrictMode>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

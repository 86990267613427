import produce from 'immer'
import { isEmpty, size, orderBy } from 'lodash'
import { useTranslation } from 'react-i18next';

import {
  SET_WELLS,
  SET_DATA_FOR_WELL
} from './actions'



const initialState = {

  snackbar: {
    message: '',
    show: false,
  },
  currentUser: '',
  wellDataForGraphs: [],
  wells: [
    {
      id: "004",
      name: "Gora in Illu Gelan",
      typeOfWell: "shallow",
      sensorID: "T4L04131",
      imageURL: "/004.jpeg",
      hasSensor: true,
      coordinates: [37.3421, 9.004056],
      height: "1776",
      data: [
        {
          dataName: "groundwater level",
          unit: "m",
          data: []
        },
        {
          dataName: "conductivity",
          unit: "mS/cm",
          data: []
        },
        {
          dataName: "temperatur",
          unit: "°C",
          data: []
        },
      ]
    },
    {
      id: "003",
      name: "Asgori in Illu Gelan",
      typeOfWell: "shallow",
      sensorID: "T4L04128",
      imageURL: "/003.jpeg",
      hasSensor: true,
      coordinates: [37.347917, 8.995333],
      height: "1735",
      data: [
        {
          dataName: "groundwater level",
          unit: "m",
          data: []
        },
        {
          dataName: "conductivity",
          unit: "mS/cm",
          data: []
        },
        {
          dataName: "temperatur",
          unit: "°C",
          data: []
        },

      ]
    },
    {
      id: "002",
      name: "Lemi Robite in Borena",
      typeOfWell: "deep",
      sensorID: "T4L04132",
      imageURL: "/002.jpeg",
      hasSensor: true,
      coordinates: [38.822400, 10.706597],
      height: "2424",
      data: [
        {
          dataName: "groundwater level",
          unit: "m",
          data: []
        },
        {
          dataName: "conductivity",
          unit: "mS/cm",
          data: []
        },
        {
          dataName: "temperatur",
          unit: "°C",
          data: []
        },
      ]
    },
    {
      id: "010",
      name: "Addis Alem Golba, Borena",
      typeOfWell: "handDug",
      sensorID: "T4L04124",
      imageURL: "/010.jpeg",
      hasSensor: true,
      coordinates: [38.766389, 10.758056],
      height: "2619",
      data: [
        {
          dataName: "groundwater level",
          unit: "m",
          data: []
        },
        {
          dataName: "conductivity",
          unit: "mS/cm",
          data: []
        },
        {
          dataName: "temperatur",
          unit: "°C",
          data: []
        },

      ]
    },
    {
      id: "005",
      name: "Siba Keta in Nono Benja",
      typeOfWell: "shallow",
      sensorID: "T4L04129",
      imageURL: "/011.jpeg",
      hasSensor: true,
      coordinates: [37.147450, 8.716050],
      height: "1618",
      data: [
        {
          dataName: "groundwater level",
          unit: "m",
          data: []
        },
        {
          dataName: "conductivity",
          unit: "mS/cm",
          data: []
        },
        {
          dataName: "temperatur",
          unit: "°C",
          data: []
        },
      ]
    },
    {
      id: "006",
      name: "Soloka, Gemeta Damma in Nono Benja",
      typeOfWell: "shallow",
      sensorID: "T4L04130",
      imageURL: "/012.jpeg",
      hasSensor: true,
      coordinates: [37.026069, 8.582872],
      height: "1699",
      data: [
        {
          dataName: "groundwater level",
          unit: "m",
          data: []
        },
        {
          dataName: "conductivity",
          unit: "mS/cm",
          data: []
        },
        {
          dataName: "temperatur",
          unit: "°C",
          data: []
        },
      ]
    },

    // {
    //   id: "001",
    //   name: "Jibat 1 Sayo in Dano",
    //   typeOfWell: "deep",
    //   sensorID: "",
    //   imageURL: "/001.jpeg",
    //   hasSensor: false,
    //   coordinates: [37.337489, 8.706367],
    //   height: "2060",
    //   data: [
    //     {
    //       dataName: "groundwater level",
    //       unit: "m",
    //       data: []
    //     },
    //     {
    //       dataName: "conductivity",
    //       unit: "mS/cm",
    //       data: []
    //     },
    //     {
    //       dataName: "temperatur",
    //       unit: "°C",
    //       data: []
    //     },
    //   ]
    // },
    // {
    //   id: "007",
    //   name: "Kata, Dano",
    //   typeOfWell: "handDug",
    //   sensorID: "",
    //   imageURL: "/007.jpeg",
    //   hasSensor: false,
    //   coordinates: [37.322618, 8.826608],
    //   height: "1629",
    //   data: [
    //     {
    //       dataName: "groundwater level",
    //       unit: "m",
    //       data: []
    //     },
    //     {
    //       dataName: "conductivity",
    //       unit: "mS/cm",
    //       data: []
    //     },
    //     {
    //       dataName: "temperatur",
    //       unit: "°C",
    //       data: []
    //     },
    //   ]
    // },
    // {
    //   id: "008",
    //   name: "Horsisi, Dano",
    //   typeOfWell: "handDug",
    //   sensorID: "",
    //   imageURL: "/008.jpeg",
    //   hasSensor: false,
    //   coordinates: [37.285841, 8.794743],
    //   height: "1673",
    //   data: [
    //     {
    //       dataName: "groundwater level",
    //       unit: "m",
    //       data: []
    //     },
    //     {
    //       dataName: "conductivity",
    //       unit: "mS/cm",
    //       data: []
    //     },
    //     {
    //       dataName: "temperatur",
    //       unit: "°C",
    //       data: []
    //     },
    //   ]
    // },
    // {
    //   id: "009",
    //   name: "Kasa Cheffe, Borena",
    //   typeOfWell: "handDug",
    //   sensorID: "",
    //   imageURL: "/009.jpeg",
    //   hasSensor: false,
    //   coordinates: [38.829167, 10.795556],
    //   height: "2585",
    //   data: [
    //     {
    //       dataName: "groundwater level",
    //       unit: "m",
    //       data: []
    //     },
    //     {
    //       dataName: "conductivity",
    //       unit: "mS/cm",
    //       data: []
    //     },
    //     {
    //       dataName: "temperatur",
    //       unit: "°C",
    //       data: []
    //     },
    //   ]
    // }
  ]
}

const reducer = produce((draft, action) => {
  switch (action.type) {

    case SET_WELLS:
      draft.wells = action.wells
      return

    case SET_DATA_FOR_WELL:
      let well = draft.wells.find(well => well.sensorID === action.sensor_id)
      console.log(well, action.sensor_id)
      if (well) {
        switch (action.sensor_application) {
          case "conductivity":

            well.data[1].data = action.data
            break;

          case "temperatur":

            well.data[2].data = action.data
            break;

          case "groundwater level":

            well.data[0].data = action.data
            break;

          default:
            break;
        }
        return
      }

    default:
      return draft
  }
}, initialState)

export default reducer
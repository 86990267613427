import logo from './img/logo.svg';

import React from 'react'
import { Route } from 'react-router'

import ReactDom from 'react-dom';
import Home from './pages/Home'

const App = (props) => {
  return (
    <div className="App">
      <Route exact path='/' component={Home} />
    </div>
  );
}

export default App;


import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import PlotlyChart from './PlotlyChart'
import NewPlotlyChart from './NewPlotlyChart'
import chartOptions from './ChartOptions'
import { Disclosure, Tab, Transition } from '@headlessui/react'
import { useTranslation } from 'react-i18next';



import {
    fetchWellData
} from '../store/actions'
// import { Link, useHistory } from 'react-router-dom'
// import Select from 'react-select'
// import { useSelector, useDispatch } from 'react-redux'
// import { useTranslation } from "react-i18next"
// import { isEmpty } from 'lodash'






const Dashboard = (props) => {
    //const history = useHistory()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const wells = useSelector(state => state.wells)
    const [welldata, setWelldata] = useState([]);
    const [loaded, setLoaded] = useState(false)

    const [dp, setDp] = useState(0)


    function generatePlotlyGraphData(wd, x, y, meta, index) {
        let showInLedgendTag = 'legendonly'
        if (meta.name == 'groundwater level') {
            showInLedgendTag = ""
        }

        wd[index].data.push({
            mode: "lines",
            visible: showInLedgendTag,
            name: meta.name + ' (' + meta.unit + ')',
            line: {
                color: "rgb(" + meta.color + ")",
                width: 2
            },
            x: x,
            y: y,
            type: 'scatter'
        })
    }

    function generateMiniGraphData(sensorData, meta, index, name) {

        let localChartOptions = chartOptions
        let localSensorData = sensorData.slice(Math.max(sensorData.length - 300, 1))

        console.log(name)

        if (name == 'groundwater level') {
            chartOptions.scales.yAxes[0].ticks.reverse = true
        } else {
            chartOptions.scales.yAxes[0].ticks.reverse = false
        }


        localChartOptions.scales.yAxes[0].ticks.suggestedMin = (Math.min(...localSensorData)) - (Math.min(...localSensorData) * 0.01)
        localChartOptions.scales.yAxes[0].ticks.suggestedMax = (Math.max(...localSensorData) + (Math.max(...localSensorData) * 0.001))

        if (document.getElementById('chart' + index + name)) {
            var ctx = document.getElementById('chart' + index + name).getContext('2d');
            var chart = new Chart(ctx, {
                type: "line",
                data: {
                    labels: localSensorData,
                    datasets: [
                        {
                            /* backgroundColor: "rgba(" + meta.color + ", 0.1)", */
                            backgroundColor: "rgba(" + meta.color + ", 0.0)",
                            borderColor: "rgba(" + meta.color + ", 0.8)",
                            borderWidth: 2,
                            data: localSensorData,
                        },
                    ],
                },
                options: chartOptions
            });
        }
    }
    function checkIfSensorIsOnline(lastDateFromSensor) {
        if (lastDateFromSensor) {
            let todayMinus2Days = new Date();
            let lastDate = new Date(lastDateFromSensor.time)
            todayMinus2Days.setDate(todayMinus2Days.getDate() - 3);

            if (lastDate > todayMinus2Days) {
                return true
            }
            return false
        }
        return false
    }

    function getStyleForGraphByDataName(sensorName) {
        let meta = {
            name: "",
            color: ""
        }
        switch (sensorName) {
            case "conductivity":
                meta.name = 'Conductivity'
                meta.unit = 'mS/cm'
                meta.color = '245, 158, 1'
                break;
            case "temperatur":
                meta.name = 'Water Temperatur'
                meta.unit = '°C'
                meta.color = '228, 213, 3'
                break;
            case "groundwater level":
                meta.name = 'groundwater level'
                meta.unit = 'm'
                meta.color = '64, 122, 188'
                break;
            default:
                break;
        }
        return meta
    }

    function generateGraphs() {
        let wellIndex = 0
        let local_wd = []
        wells.forEach(location => {
            local_wd.push({
                index: wellIndex,
                data: []
            })

            location.data.forEach(sensorData => {
                let times = sensorData.data.map(data => data.time)
                let values = sensorData.data.map(data => data.value)
                generatePlotlyGraphData(local_wd, times, values, getStyleForGraphByDataName(sensorData.dataName), wellIndex)
                generateMiniGraphData(values, getStyleForGraphByDataName(sensorData.dataName), wellIndex, sensorData.dataName)
            });
            wellIndex++
        });
        return local_wd
    }


    useEffect(() => {
        dispatch(fetchWellData()).then(() => {
            console.log("ETT")
        })
        setLoaded(true)
    }, [])

    let wd = generateGraphs();

    const dpHandler = (num) => {
        setDp(num);
        /* alert(num); */
        console.log(num);
    };


    return (
        <div>

            {/* Well Picture and Infos & Icons */}
            {loaded && wells.map((value, index) => {
                return <div key={index} className="pb-6 scroll-margin-top" id={"dashboard-" + value.id} >


                    <div className="min-w-screen bg-gray-100 flex flex-wrap px-5 py-5 rounded-lg shadow-lg justify-center">
                        <div className="w-full flex flex-wrap pb-6 justify-center xl:justify-start">
                            <a className='cursor-pointer hover:opacity-70 w-full xl:w-auto flex justify-center' href={"img/wells" + value.imageURL} target='_blank'>
                                <img
                                    className="h-36 w-36 rounded-xl object-cover"
                                    src={"img/wells" + value.imageURL}
                                    alt=""
                                />
                            </a>
                            <div className="my-auto pl-4">

                                <h2 className="text-3xl flex justify-center xl:justify-start mt-4">{value.name}</h2>
                                <div className="flex flex-wrap  flex justify-center xl:justify-start flex-column xl:flex-row">

                                    <div className="flex flex-row">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-prime" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                        </svg>
                                        <p className="text-gray-600 pl-1 text-sm">{value.coordinates[1]}, {value.coordinates[0]}</p>

                                        <svg xmlns="http://www.w3.org/2000/svg" className="ml-3 h-5 w-5 text-prime" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M7 11l5-5m0 0l5 5m-5-5v12" />
                                        </svg>
                                        <p className="text-gray-600 pl-1  text-sm">{value.height} m</p>
                                    </div>

                                    <div className="flex flex-row">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="ml-3 h-5 w-5 text-prime" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                        <p className="text-gray-600 pl-1  text-sm">{t('well.' + value.typeOfWell)}</p>
                                    </div>

                                    <div className="flex flex-row">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="ml-3 h-5 w-5 text-prime" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                        <p className="text-gray-600 pl-1  text-sm">{t('update.text')} {value.data[0].data.length > 0 &&
                                            value.data[0].data[value.data[0].data.length - 1] && new Date(value.data[0].data[value.data[0].data.length - 1].time).toLocaleDateString()} - {value.data[0].data[value.data[0].data.length - 1] && new Date(value.data[0].data[value.data[0].data.length - 1].time).toLocaleTimeString()}</p>



                                        {value.sensorID && (
                                            <>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="ml-3 h-5 w-5 text-prime" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z" />
                                                </svg>
                                                <p className="text-gray-600 pl-1  text-sm">{value.sensorID} </p>
                                            </>
                                        )}

                                    </div>
                                </div>
                                <div class="mt-4  flex justify-center xl:justify-start">
                                    {checkIfSensorIsOnline(value.data[2].data[(value.data[2].data.length - 1)]) && (
                                        <div class="flex justify-center items-center font-medium py-1 px-2 rounded-full text-green-700 bg-green-100 border border-green-300 w-20">
                                            <div class="text-xs font-normal leading-none max-w-full flex-initial">Online</div>
                                        </div>
                                    )}
                                    {!checkIfSensorIsOnline(value.data[2].data[(value.data[2].data.length - 1)]) && (
                                        <div class="flex justify-center items-center font-medium py-1 px-2 rounded-full text-yellow-700 bg-yellow-100 border border-yellow-300 w-20">
                                            <div class="text-xs font-normal leading-none max-w-full flex-initial">Offline</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>


                        {/* Small graphs and big graph(s) after clicking opening button(s) */}
                        {value.data[0].data.length > 0 && (
                            <>
                                <div className="w-full ">

                                    {/* Grid System for graphs */}
                                    <div className="">

                                        <Tab.Group defaultIndex={''}>
                                            <Tab.List className="grid grid-cols-1 gap-4 md:grid-cols-3">
                                                <Tab className="hidden"></Tab>

                                                {/* Small graphs */}
                                                {value.data.map((value, sensorIndex) => {
                                                    console.log("render", sensorIndex)
                                                    return <div className="w-full" key={sensorIndex}>

                                                        <Tab className="w-full">
                                                            <div className="rounded-lg shadow-sm mb-4 w-full">
                                                                <div className="rounded-lg bg-white shadow-lg lg:shadow-xl relative overflow-hidden">
                                                                    <div className="px-3 pt-8 pb-10 text-center relative z-10">
                                                                        <h4 className="text-sm uppercase text-gray-500 leading-tight">{getStyleForGraphByDataName(value.dataName).name}</h4>
                                                                        <h3 className="text-3xl text-gray-700 font-semibold leading-tight my-3">{Number(value.data[value.data.length - 1] && value.data[value.data.length - 1].value).toFixed(2)} {getStyleForGraphByDataName(value.dataName).unit}</h3>
                                                                        <small></small>
                                                                    </div>
                                                                    <div className="absolute bottom-0 inset-x-0">
                                                                        {<canvas id={"chart" + index + value.dataName} height="80"></canvas>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Tab>

                                                    </div>
                                                })}


                                            </Tab.List>
                                            <Tab.Panels className="block">
                                                <Tab.Panel></Tab.Panel>
                                                <Tab.Panel>  <div className="flex ">
                                                    <div className="w-full">
                                                        <div className="rounded-lg shadow-sm mb-4 ">
                                                            <div className="rounded-lg bg-white shadow-lg lg:shadow-xl relative overflow-hidden ">
                                                                <section>
                                                                    <div className="w-full flex flex-wrap justify-center">
                                                                        <div className="w-full pt-12">
                                                                            <h4 className="text-sm text-gray-500 leading-tight pl-10">Data for: {value.name}</h4>
                                                                            {wd[index] && (
                                                                                console.log(wd[index]),
                                                                                <>
                                                                                    <NewPlotlyChart name={value.id} data={wd[index].data[0]} firstGraph={true}></NewPlotlyChart>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </Tab.Panel>
                                                <Tab.Panel><div className="flex ">
                                                    <div className="w-full">
                                                        <div className="rounded-lg shadow-sm mb-4 ">
                                                            <div className="rounded-lg bg-white shadow-lg lg:shadow-xl relative overflow-hidden ">
                                                                <section>
                                                                    <div className="w-full flex flex-wrap justify-center">
                                                                        <div className="w-full pt-12">
                                                                            <h4 className="text-sm text-gray-500 leading-tight pl-10">Data for: {value.name}</h4>
                                                                            {wd[index] && (
                                                                                console.log(wd[index].data[0]),
                                                                                <>
                                                                                    <NewPlotlyChart name={value.id} data={wd[index].data[1]} secondGraph={true}></NewPlotlyChart>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </Tab.Panel>
                                                <Tab.Panel>
                                                    <div className="flex ">
                                                        <div className="w-full">
                                                            <div className="rounded-lg shadow-sm mb-4 ">
                                                                <div className="rounded-lg bg-white shadow-lg lg:shadow-xl relative overflow-hidden ">
                                                                    <section>
                                                                        <div className="w-full flex flex-wrap justify-center">
                                                                            <div className="w-full pt-12">
                                                                                <h4 className="text-sm text-gray-500 leading-tight pl-10">Data for: {value.name}</h4>
                                                                                {wd[index] && (
                                                                                    console.log(wd[index].data[0]),
                                                                                    <>
                                                                                        <NewPlotlyChart name={value.id} data={wd[index].data[2]} thirdGraph={true}></NewPlotlyChart>
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab.Panel>
                                            </Tab.Panels>
                                        </Tab.Group>

                                    </div>

                                </div>

                            </>
                        )}
                        {!(value.data[0].data.length > 0) && (
                            <div className="w-full text-center font-bold text-gray-600">{t('noData.text')}</div>
                        )}
                    </div>


                </div>

            })}


        </div>
    )
}

export default Dashboard

import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ReadMore = ({ children }) => {
  const lngs = {
    en: { nativeName: 'EN' },
    de: { nativeName: 'DE' }
  };
  const { t, i18n } = useTranslation();
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text-lg pt-2 text-gray-700">
      {isReadMore ? text.slice(0, 300) : text}
      <span onClick={toggleReadMore} className="read-or-hide font-bold cursor-pointer">
        {isReadMore ? t('readMore.more') : t('readMore.less')}
      </span>
    </p>
  );
};
export default ReadMore
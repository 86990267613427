import React, { useEffect } from 'react'
import * as Plotly from 'plotly.js'

const NewPlotlyChart = (props) => {

    console.log(props.data.x)

    let layout = { }
    if (props.firstGraph){layout = {colorway: ['#6c97ca'], yaxis: {autorange:"reversed"}}}
    if (props.secondGraph){layout = {colorway: ['#f7b150']}}
    if (props.thirdGraph){layout = {colorway: ['#e9dc53']}}

    var trace1 = {
        x: props.data.x,
        y: props.data.y,
        type: 'scatter'
      };

      var data = [trace1];
      
  useEffect(() => {

    Plotly.newPlot(props.name, data, layout);
  });


  return (
    <div className="w-full relative block clear-both lg:pl-6 pr-4">
      <div id={props.name}></div>
    </div>
  )
}

export default NewPlotlyChart
